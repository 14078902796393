import day1 from "./imgs/1.png";
import day2 from "./imgs/2.png";
import day3 from "./imgs/3.png";
import day4 from "./imgs/4.png";
import day5 from "./imgs/5.png";
import day6 from "./imgs/6.png";
import day7 from "./imgs/7.png";
import day8 from "./imgs/8.png";
import day9 from "./imgs/9.png";
import day10 from "./imgs/10.png";
import day11 from "./imgs/11.png";
import day12 from "./imgs/12.png";
import day13 from "./imgs/13.png";
import day1416 from "./imgs/14-16.png";
import day17 from "./imgs/17.png";
import day18 from "./imgs/18.png";
import day19 from "./imgs/19.png";
import day20 from "./imgs/20.png";
import day21 from "./imgs/21.png";
import day22 from "./imgs/22.png";
import day23 from "./imgs/23.png";
import day24 from "./imgs/24.png";
import day25 from "./imgs/25.png";
import day26 from "./imgs/26.png";
import day27 from "./imgs/27.png";
import day28 from "./imgs/28.png";
import day2930 from "./imgs/29+30.png";
import { ReactNode } from "react";

interface Day {
  imageSrc: string;
  key: string;
  title: string;
  description: ReactNode;
}

export const days: Day[] = [
  {
    key: "nskjh42jk",
    imageSrc: day1,
    title: "🍿 Movie Time!",
    description:
      "Let's start this off easy. Need to stay fresh for the weekend!",
  },
  {
    key: "fflsiu423i4",
    imageSrc: day2,
    title: "🏰 Bunker Art",
    description: (
      <>
        <p>
          ❓ Did you know before Berghain there was Ostgut, and before Ostgut
          there was 'The Bunker' ?
        </p>
        <p>
          🎨 And did you know it now houses the private art collection of some
          wealthy people?
        </p>
        <p>🏎 We're going to visit it!</p>
      </>
    ),
  },
  {
    key: "vncxl4u342",
    imageSrc: day3,
    title: "🍣 YUMMM",
    description: (
      <>
        <p>You can probably guess what we're going to do. 🍽</p>
      </>
    ),
  },
  {
    key: "bnoip243o2p",
    imageSrc: day4,
    title: "🧖‍♀️ Va-ba-li. VABALI!",
    description: (
      <>
        <p>🤗 Ooh yesss. It's a busy weekend! Let's go and sweat it off.</p>
      </>
    ),
  },
  {
    key: "5940fsksdu",
    imageSrc: day5,
    title: "🥘 Hungry?",
    description: (
      <>
        <p>🗼 Let's go somewhere special, with a view.</p>
        <p>❓ Can you guess?</p>
      </>
    ),
  },
  {
    key: "nm57654345",
    imageSrc: day6,
    title: "🎄 Our final Christmas days in Berlin!",
    description: (
      <>
        <p>
          🤶 Let's go take advantage one last time of the fact that we live in
          Germany!
        </p>
        <p>🎅 Let's go to the Christmas market one more time!</p>
      </>
    ),
  },
  {
    key: "5mklhksdfhii",
    imageSrc: day7,
    title: "🌺 We're in Hawaii!",
    description: (
      <>
        <p>
          🌴 Maui is supposed to have this beautiful park{" "}
          <strong>Twin Falls</strong>
        </p>
        <p>🥾 Let's go an explore, and spend some awesome time together ❤️</p>
      </>
    ),
  },
  {
    key: "nmlp12309zsd",
    imageSrc: day8,
    title: "🏯 We're in Japan!",
    description: (
      <>
        <p>🪶 Ooh right we're in Phoenix. Sorry my bad.</p>
        <p>🤔 Then why are there Japanese gardens here?</p>
        <p>🚶‍♂️ Let's go for a nice walk there!</p>
      </>
    ),
  },
  {
    key: "nfdmsklj4h243",
    imageSrc: day9,
    title: "🏰 Potsdam!",
    description: (
      <>
        <p>🧚‍♀️ Fairytales</p>
        <p>🥾 Let's go enjoy some time in the beautiful forests of Postdam!</p>
      </>
    ),
  },
  {
    key: "iou5h3jk5h43j",
    imageSrc: day10,
    title: "🍕 Time for a laugh",
    description: (
      <>
        <p>🐟 What did a fish say when he swam into the wall?</p>
        <p>?</p>
        <p>?</p>
        <p>
          <strong>Dam</strong>
        </p>
        <p>🤣🤣🤣🤣</p>
        <p>
          🎤 There's people who do this better. Let's go have pizza & a laugh at
          Cosmic Comedy!
        </p>
      </>
    ),
  },
  {
    key: "vnkljku4j2l34",
    imageSrc: day11,
    title: "🕵️‍♂️ Puzzle Time",
    description: (
      <>
        <p>📱 We're going to do a "Third Reich" puzzle walk!</p>
        <p>⛺️ Using Questo we'll spend an hour discovering Nazi-Berlin!</p>
      </>
    ),
  },
  {
    key: "32908fdsfjk",
    imageSrc: day12,
    title: "🍻 Smokey bar time!",
    description: (
      <>
        <p>🚬 Let's go have a drink at our favorite smokey bar near Kotti!</p>
      </>
    ),
  },
  {
    key: "opibjjdbnm4j",
    imageSrc: day13,
    title: "🪆 Life in Eastern Germany",
    description: (
      <>
        <p>
          🏭 In the Kulturbrauerei there's this museum about life in the DDR
        </p>
        <p>👀 Let's go take a look togeeeeethhher!</p>
      </>
    ),
  },
  {
    key: "xzchtewhjkh43",
    imageSrc: day1416,
    title: "🧖 Nature & Chill",
    description: (
      <>
        <p>🏰 3 days of walking, wellness and food!</p>
        <p>
          🌳 We're going to{" "}
          <a href="https://www.spree-balance.de/en/home" className="link">
            Hotel "Spree Balance"!
          </a>
        </p>
      </>
    ),
  },
  {
    key: "53978cxasdnmbkj",
    imageSrc: day17,
    title: "🌭 Homemade Fancy Dinner",
    description: (
      <>
        <p>
          🧑‍🍳 An ingredient unique to the homekitchen is <i>love</i>.
        </p>
        <p>
          🥘 I'm going to make <span className="line-through">love to</span>{" "}
          food for you tonight ❤️.
        </p>
      </>
    ),
  },
  {
    key: "zaoureiowfbn",
    imageSrc: day18,
    title: "⛺️ Sad Camp",
    description: (
      <>
        <p>🇩🇪 Germany was very naughty in the 40s</p>
        <p>😕 Let's go visit one of the camps</p>
      </>
    ),
  },
  {
    key: "vjzkuiorui2",
    imageSrc: day19,
    title: "💨 Shisha!",
    description: (
      <>
        <p>
          🥸 Couldn't miss 30 days of quality time without one of our basic
          favorites.
        </p>
      </>
    ),
  },
  {
    key: "fdsnvcxmnnalksk",
    imageSrc: day20,
    title: "🏙 Walking Tour",
    description: (
      <>
        <p>
          🥾 Nothing special. Let's go on a walk to discover the "Wedding"
          neighborhood using the booklet!
        </p>
      </>
    ),
  },
  {
    key: "5798gxvbasjo",
    imageSrc: day21,
    title: "☕️ Coffee is golden",
    description: (
      <>
        <p>🛋 I love spending time with you at the coffee-house!</p>
        <p>🔎 Let's find a cute one and have a cup.</p>
      </>
    ),
  },
  {
    key: "0h9892nmbmczx",
    imageSrc: day22,
    title: "🏥 Creepy",
    description: (
      <>
        <p>🥷 There's an old Children's Hospital you can go visit</p>
        <p>🦮 Adventure Time!</p>
      </>
    ),
  },
  {
    key: "gbrtkwequyk23",
    imageSrc: day23,
    title: "🏆 Victory Pole",
    description: (
      <>
        <p>🏳️‍🌈 So much pride in the Germans. They erect poles for victories.</p>
        <p>🧗‍♀️ Let's go climb up the on in Tiergarten!</p>
      </>
    ),
  },
  {
    key: "zvbcvjkouwr543",
    imageSrc: day24,
    title: "🌳 Grunewald",
    description: (
      <>
        <p>🎒 We still haven't been! Let's go enjoy :{")"}</p>
      </>
    ),
  },
  {
    key: "dot7b64jbgkd",
    imageSrc: day25,
    title: "🎨 Drinking & Drawing",
    description: (
      <>
        <p>🍕 First we'll have TASTY pizza!</p>
        <p>👨‍🎨 Then we'll go to "Drink & Draw" to paint toggeeettther!</p>
      </>
    ),
  },
  {
    key: "g9h7rh3bhvhkdoby",
    imageSrc: day26,
    title: "🧩 Boardgame night!",
    description: (
      <>
        <p>🍺 Let's go enjoy a boardgame and a drink</p>
        <p>👩‍❤️‍💋‍👨 Togeeettthher</p>
      </>
    ),
  },
  {
    key: "gbi9r63hgvfdfsgo",
    imageSrc: day27,
    title: "🏘 Friedichshain Walk",
    description: (
      <>
        <p>👀 The mysterious neighborhood, what will the booklet show?</p>
      </>
    ),
  },
  {
    key: "hjnyopopyb321",
    imageSrc: day28,
    title: "🛤 Walky Time!",
    description: (
      <>
        <p>🌳 Outside of Berlin there's an old trainyard turned into a park</p>
        <p>🥾 Let's put on our walking shoes one last time, and go visit!</p>
      </>
    ),
  },
  {
    key: "bshgpi5o87hfgjk",
    imageSrc: day2930,
    title: "🧀 Windmills & Wooden Shoes",
    description: (
      <>
        <p>🧖‍♀️ Like we haven't head enough, let's do 1 more day of spa-ing!</p>
        <p>🏖 Just making sure you're EXTRA relaxed</p>
        <p>
          ❤️ Since this is the last event, I wanna take a moment to thank you
          for being on this journey with me!
        </p>
        <p>
          🥰 Not only this last month with all the fun events, also our entire
          (almost) five years together!
        </p>
        <p>
          🎉 You're well into you 30th age, and we're only going onward and
          upwards!!
        </p>
        <p>🛎 Now let's go enjoy this final spa</p>
        <img
          src="https://media.giphy.com/media/lTQF0ODLLjhza/giphy.gif"
          alt="kiss"
          className="w-full"
        />
      </>
    ),
  },
];

import { Layout } from "./Layout";

export const Error404 = () => {
  return (
    <Layout
      imageSrc="https://media.giphy.com/media/d2jjuAZzDSVLZ5kI/giphy.gif"
      title="😭 Page not found"
      description={<p>Time to head back!</p>}
    />
  );
};

import { Layout } from "./Layout";

export default function Home() {
  return (
    <Layout
      imageSrc="https://media.giphy.com/media/26BRtW4zppWWjrsPu/giphy.gif"
      title="🎉 It's yo Birthday"
      description={
        <>
          <p>
            😍 What an honor to be spending your <strong>30th!</strong> birthday
            with you.
          </p>
          <p>
            📅 The number 30 is one of wisdom and adventure. It's the phase of
            life where you will both increase external adventure and decrease
            internal turmoil.
          </p>
          <p>
            🚀 Let's bring this year to a <i>banging</i> start together. It's
            perfect; we will both have some extra time soon, so I want to invite
            you to a 30-days-of-fun adventure with me!
          </p>
          <p>
            😎 30 days of fun with your love! 30 days of chill with your boyyy.
            Are you excited?!!??!
          </p>
          <p><strong>😍 I am 😍</strong></p>
        </>
      }
    />
  );
}

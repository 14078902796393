import { FC } from "react";
import { useParams } from "react-router-dom";
import { Error404 } from "./404";
import { days } from "./days";
import { Layout } from "./Layout";

export const DayView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const day = days.find((d) => d.key === id);

  if (!day) {
    return <Error404 />;
  }

  return (
    <Layout
      imageSrc={day.imageSrc}
      title={day.title}
      description={day.description}
    />
  );
};

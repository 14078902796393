import { ReactNode } from "react";

interface IProps {
  imageSrc: string;
  title: string;
  description: ReactNode;
}

export const Layout = ({ imageSrc, title, description }: IProps) => {
  return (
    <div className="flex flex-col items-center max-w-md mx-auto">
      <img src={imageSrc} alt="birthday gif" className="w-full" />
      <div className="prose text-center">
        <h1 className="mt-16 mb-8">{title}</h1>
        {description}
      </div>
    </div>
  );
};
